import React from "react";
// Customizable Area Start
import { Button, Divider, Grid, Box, Typography, Badge, Avatar, Container } from "@material-ui/core";
import { t } from "i18next";
import CreateOutlined from '@material-ui/icons/CreateOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { user4 } from '../assets'
interface UserProfileInterface {
    user: any
}
interface BasicDetailsDataInterface {
    leftText: string
    rightText: string
}
interface HobbyDetailsDataInterface {
    title: string
    hobby: string[]
}
interface UserInfoInterface {
    text: string
    style: Object
}
const UserAboutMe = (props: UserProfileInterface) => {
    const { user } = props
    const skills: string[] = [
        'Football', 'drawing', 'skee', 'dacing', 'teaching ideas', 'property rent & sale'
    ]
    const data = [
        {
            title: "Software Engineer",
            description: [
                "Dedicated to fostering community growth through efforts and innovative solutions",
                "Dedicated to fostering communit",
                "Ready to role",
            ]
        },
        {
            title: "Devloper",
            description: [
                "Dedicated to fostering community growth through efforts and innovative solutions",
                "Dedicated to fostering communit",
                "Ready to role",
            ]
        },
    ];
    const items = [
        'Dedicated to fostering community growth through efforts and innovative solutions',
        'Dedicated to fostering communit',
        'https://uiw.builder.ai/uiworkspace/2539/whiteboard'

    ];
    const renderBasicDetails = (basicDetailsData: BasicDetailsDataInterface) => {
        return (
            <Grid container spacing={0} style={{ borderBottom: '1px solid #ccc' }}>
                <Grid className="text_first_tb_grid" item xs={6} sm={6}>
                    <Typography className="text_first_tb">{t(basicDetailsData.leftText)}</Typography>
                </Grid>
                <Grid container className="text_second_tb_grid" item xs={6} sm={6}>
                    <Typography className="text_first_tb">{basicDetailsData.rightText}</Typography>
                </Grid>
            </Grid>
        )
    }

    const renderHobbyDetails = (hobbyDetails: HobbyDetailsDataInterface) => {
        return (
            <Grid container spacing={0} style={{ borderBottom: '1px solid #ccc' }}>
                <Grid item xs={12} sm={6} className="text_first_tb_grid">
                    <Typography className="text_first_tb">{t(hobbyDetails.title)}</Typography>
                </Grid>

                <Grid container item xs={12} sm={6} className="skils_second_tb_grid">
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                    }}>
                        {hobbyDetails.hobby.map((item: string) => {
                            return (<div style={{ marginInline: "5px", width: "max-content" }}><Button className="text_button_main"
                                variant="contained"
                            >
                                <div className="tag-interest">{item}</div>
                            </Button></div>)
                        })}
                        <Button variant="text" className="show_less_text">{t('Show Less')}</Button>
                    </div>
                </Grid>
            </Grid>
        )
    }

    const renderPointsList = (items: string[]) => {
        return (
            <Grid container spacing={1}>
                {items.map((item, index) => (
                    <Grid item xs={12} sm={12} md={12} key={index}>
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <FiberManualRecordIcon fontSize="small"
                                style={{
                                    color: '#475569',
                                    fontSize: "12px",
                                    marginRight: "8px",
                                    marginTop: '2px'
                                }} />
                            <Typography className="affilation_text">{item}</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        )

    }
    const renderUserInfo = (info: UserInfoInterface) => {
        return (
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} container style={{ justifyContent: 'center' }}>
                <Typography
                    variant="subtitle2"
                    style={info.style}
                >{info.text}</Typography>
            </Grid>
        )
    }
    return (
        <Box>
            <Box>
                <Grid container className="user_about_me_container">
                    <Grid item container xs={2}></Grid>
                    <Grid item container xs={8} style={{ justifyContent: 'center' }}>
                        <Avatar
                            src={(!!user && user.photo) || user4}
                            alt="User Profile Picture"
                            style={{
                                width: 120,
                                height: 120,
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Typography
                            variant="subtitle2"
                            className="edit_profile_button"
                        >{t("Edit Profile")}</Typography>
                        <Badge style={{ cursor: "pointer" }}>
                            <CreateOutlined
                                style={{
                                    color: '#64748B',
                                    fontSize: 20
                                }}></CreateOutlined>
                        </Badge>
                    </Grid>
                </Grid>
                {renderUserInfo({
                    text: 'Arena Mccoy', style: {
                        fontFamily: 'Comfortaa-Bold',
                        color: '#475569',
                        fontSize: '20px',
                        fontWeight: 600,
                        lineHeight: '28px',
                        marginTop: '8px'
                    }
                })}
                {renderUserInfo({
                    text: 'Dedicated to fostering community growth through collaborative efforts and innovative solutions', style: {
                        fontFamily: 'Comfortaa-Bold',
                        color: '#475569',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '22px',
                        marginTop: '16px',
                        maxWidth: '560px',
                        textAlign: 'center'
                    }
                })}                
            </Box>
            <Box style={{ marginTop: '16px' }}>
                <Container disableGutters style={{ marginTop: '40px' }}>
                    <Divider className="show_border"></Divider>
                    {renderBasicDetails({ leftText: 'Email Address', rightText: 'em1@yopmail.com' })}
                    {renderBasicDetails({ leftText: 'Mobile Number', rightText: '+111111114' })}
                    <Grid container spacing={0} style={{ borderBottom: '1px solid #ccc' }}>
                        <Grid container item xs={6} sm={6} style={{ padding: '24px 0px 24px 0px', alignItems: "center" }}>
                            <Typography className="text_first_tb">{t('Lives in and Time Zone')}</Typography>
                        </Grid>
                        <Grid container item xs={6} sm={6} className="text_second_tb_grid">
                            <Typography className="text_first_tb">New York, Southern State, U.S, acific Daylight Time - Los Angeles (GMT-7)</Typography>
                        </Grid>
                    </Grid>
                    {renderBasicDetails({ leftText: 'Working language', rightText: 'English' })}
                    {renderBasicDetails({ leftText: 'App preferred language', rightText: 'English' })}
                    {renderBasicDetails({ leftText: 'PBA Connection', rightText: 'Trainer, Alumni' })}
                    {renderHobbyDetails({ title: 'Skills', hobby: skills })}
                    {renderHobbyDetails({ title: 'Intrests', hobby: skills })}
                    <Grid container spacing={0} style={{ borderBottom: '1px solid #ccc', paddingBottom: '16px' }}>
                        <Grid className="text_first_tb_grid">
                            <Typography className="text_first_tb">{t('Organizational affiliations and Socials')}</Typography>
                        </Grid>
                        {renderPointsList(items)}
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid className="text_first_tb_grid">
                            <Typography className="text_first_tb">{t('Summary of Expericence')}</Typography>
                        </Grid>
                        <Grid container style={{ padding: '0px 0px 24px 0px' }}>
                            {data.map((section, index) => (
                                <Box key={index}
                                    sx={{
                                        marginBottom: '16px',
                                        paddingBottom: "16px",
                                        borderBottom: '1px solid #ccc'
                                    }}>
                                    <Typography className="summary_experience_title">
                                        {index + 1}. {section.title}
                                    </Typography>
                                    {renderPointsList(section.description)}
                                </Box>
                            ))}
                        </Grid>

                    </Grid>
                </Container>
            </Box>
        </Box>
    );
};
// Customizable Area End

export default UserAboutMe;
