import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  // Customizable Area Start
  Grid,
  Paper,
  Badge,
  Avatar,
  // Customizable Area End
  IconButton,
} from "@material-ui/core";

// Customizable Area Start
import "../assets/membersdirectory.scss"
import Header from "../../../components/src/Header.web";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SearchIcon from '@material-ui/icons/Search';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FilterListIcon from '@material-ui/icons/FilterList';
import { user4 } from "./assets";
import { t } from "i18next";
import Dropdown from "./components/Dropdown";
// Customizable Area End

import MemberDirectoryController, {
  Props,
  configJSON,
} from "./MemberDirectoryController";

export default class MemberDirectory extends MemberDirectoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  membersCount = '234'
  membersData = [{
    path: user4,
    name: "Arlene McCoy",
    location: "New York, Southern State, U.S",
    mission: 'Dedicated to fostering community growth through efforts and innovative solutions',
    affiliation: [
      'Dedicated to fostering community growth through efforts and innovative solutions',
      'Dedicated to fostering communit',
      'https://uiw.builder.ai/uiworkspace/2539/whiteboard'

    ],
    skills: [
      'Football', 'property rent & sale', 'Property Rent Time',
      'property rent & sale', 'property rent & sale',
      'property rent & sale', 'property rent & sale', 'property rent & sale', 'Running', 'Teaching Ideas'
    ]

  },
  {
    path: user4,
    name: "Arlene McCoy",
    location: "New York, Southern State, U.S",
    mission: 'Dedicated to fostering community growth through efforts and innovative solutions',
    affiliation: [
      'Dedicated to fostering community growth through efforts and innovative solutions',
      'Dedicated to fostering communit',
      'https://uiw.builder.ai/uiworkspace/2539/whiteboard'

    ],
    skills: [
      'Football', 'property rent & sale', 'Property Rent Time',
      'property rent & sale', 'property rent & sale',
      'property rent & sale', 'property rent & sale', 'property rent & sale', 'Running', 'Teaching Ideas'
    ]

  },
  {
    path: user4,
    name: "Arlene McCoy",
    location: "New York, Southern State, U.S",
    mission: 'Dedicated to fostering community growth through efforts and innovative solutions',
    affiliation: [
      'Dedicated to fostering community growth through efforts and innovative solutions',
      'Dedicated to fostering communit',
      'https://uiw.builder.ai/uiworkspace/2539/whiteboard'

    ],
    skills: [
      'Football', 'property rent & sale', 'Property Rent Time',
      'property rent & sale', 'property rent & sale',
      'property rent & sale', 'property rent & sale', 'property rent & sale', 'Running', 'Teaching Ideas'
    ]

  }]

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div>
        <Header type={"MembersDirectory"} navigation={this.props.navigation} />
        <div style={{ background: "linear-gradient(to bottom, rgba(231,239,252,1) 40%, rgba(255,255,255,1) 100%)", width: '100%', height: '100%' }}>
          <Grid container className="member_directory_page">
            <Grid container spacing={4} style={{ maxWidth: '100%', width: '100%' }}>
              <Grid item xs={12} md={9} lg={9} xl={9} sm={12}>
                <Grid component={Paper} style={{ borderRadius: '8px' }}>
                  <Paper elevation={3} className="members_directory_list" >
                    <Box>
                      <Box className="md_header_box">
                        <Grid>
                          <Typography className="md_header">{t('Member Directory')}</Typography>
                          <Typography className="md_sub_header">{t('Dedicated to the community growth')}</Typography>
                        </Grid>
                        <Grid container className="members_count_row">
                          <Grid item className="members_count_block" xs={11} sm={11} >
                            <Badge>
                              <PeopleAltIcon
                                style={{
                                  color: '#5497FF',
                                  fontSize: 18
                                }}></PeopleAltIcon>
                            </Badge>
                            <Typography
                              variant="subtitle2"
                              className="member_count"
                            >{t(this.membersCount)} {t("Members")}</Typography>
                          </Grid>
                          <Grid item container className="search_icon_grid" xs={1} sm={1}>
                            <Badge>
                              <SearchIcon
                                style={{
                                  color: '#475569',
                                  fontSize: 24
                                }}></SearchIcon>
                            </Badge>
                          </Grid>
                        </Grid>
                      </Box>
                      {this.membersData.map((item: any) => {
                        return (
                          <Box className="member_card_box">
                            <Grid container spacing={1} className="member_card">
                              <Grid item container xs={12} sm={6} className="member_main_info">
                                <Grid container spacing={0} className="member_intro">
                                  <Grid className="member_avatar">
                                    <Avatar
                                      src={item.path}
                                      alt="User Profile Picture"
                                      style={{
                                        width: 56,
                                        height: 56,
                                      }}
                                    />
                                  </Grid>
                                  <Grid className="member_name">
                                    <Typography
                                      variant="subtitle2"
                                      className="md_name"
                                    >{t(item.name)}</Typography>
                                    <Grid item className="md_location_info" >
                                      <Badge style={{ marginTop: '2px' }}>
                                        <PlaceOutlinedIcon
                                          style={{
                                            color: '#475569',
                                            fontSize: 16
                                          }}></PlaceOutlinedIcon>
                                      </Badge>
                                      <Typography
                                        variant="subtitle2"
                                        className="md_location_text"
                                      >{t(item.location)}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: '16px' }}>
                                  <Typography
                                    variant="subtitle2"
                                    className="mission_title"
                                  >{t("Mission / About")}</Typography>
                                  <Box
                                    className="mission_box"
                                  >
                                    <FiberManualRecordIcon fontSize="small"
                                      style={{
                                        color: '#475569',
                                        fontSize: "10px",
                                        marginRight: "8px",
                                        marginTop: '4px'
                                      }} />
                                    <Typography className="mission_description">{t(item.mission)}</Typography>
                                  </Box>
                                </Grid>
                                <Grid style={{ marginTop: '16px'}}>
                                  <Typography
                                    variant="subtitle2"
                                    className="mission_title"
                                  >{t("Organizational affiliations and Socials")}</Typography>
                                  <Grid container spacing={1}>
                                    {item.affiliation.map((item: any, index: number) => (
                                      <Grid item xs={12} sm={12} md={12} key={index}>
                                        <Box
                                          className="oas_box"
                                        >
                                          <FiberManualRecordIcon fontSize="small"
                                            style={{
                                              color: '#475569',
                                              fontSize: "10px",
                                              marginRight: "8px",
                                              marginTop: '4px'
                                            }} />
                                          <Typography className="mission_description">{item}</Typography>
                                        </Box>
                                      </Grid>
                                    ))}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid sm={1}></Grid>
                              <Grid item container xs={12} sm={5} className="member_secondary_info">
                                <Typography
                                  variant="subtitle2"
                                  className="skills_text"
                                >{t("Skills")}</Typography>
                                <Grid container item className="skils_grid">
                                  <div style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}>
                                    {item.skills.map((item: string) => {
                                      return (<div style={{ marginInline: "5px", width: "max-content" }}><Button className="skills_button"
                                        variant="contained"
                                      >
                                        <div className="tag-interest">{item}</div>
                                      </Button></div>)
                                    })}
                                    <Button variant="text" className="plus_skills">{t('+3 More')}</Button>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>)
                      })}
                    </Box>
                  </Paper>
                </Grid>

              </Grid>
              <Grid item xs={12} md={3} lg={3} xl={3} sm={12}>
                <Paper elevation={3} className="members_filter_box" >
                  <Box>
                    <Grid container className="filter_clear_grid">
                      <Grid style={{ display: 'flex' }}>
                        <FilterListIcon style={{
                          color: '#475569',
                          fontSize: "24px",
                        }} />
                        <Typography variant="h6" className="filter_text">
                          {t('Filter')}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Button
                          variant="text"
                          className="clear_all_text"
                          onClick={this.clearAllFilters}
                          data-testid='clear_All_Button'
                        >
                          {t('Clear All')}
                        </Button>
                      </Grid>
                    </Grid>
                    <Dropdown
                      data-test-id="language-dropdown"
                      heading='Working Language'
                      placeholderText="Language"
                      list={[
                        'English', 'Hindi', 'Marathi'
                      ]}
                      selectedState={
                        this.setLanguage
                      }
                      clearSelection={this.state.clearFilters} />
                    <Dropdown clearSelection={this.state.clearFilters} selectedState={this.setCountry} heading='Country' placeholderText="Country" list={[
                      'India', 'USA', 'New Zealand', 'Japan', 'Canada', 'Australia',
                      'Germany', 'France', 'Italy', 'Spain',
                    ]} />
                    <Dropdown clearSelection={this.state.clearFilters} selectedState={this.setGivenState} heading='State' placeholderText="State" list={[
                      'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan'
                    ]} />
                    <Dropdown clearSelection={this.state.clearFilters} selectedState={this.setCity} heading='City' placeholderText="City" list={['Mumbai', 'Pune', 'Nagpur', 'Nashik', 'Thane', 'Aurangabad', 'Solapur', 'Kolhapur', 'Sangli', 'Jalna', 'Akola', 'Amravati', 'Bhiwandi', 'Ulhasnagar', 'Kalyan', 'Dhule', 'Nanded'
                    ]} />
                    <Dropdown clearSelection={this.state.clearFilters} selectedState={this.setRegion} heading='Region' placeholderText="Region" list={['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Northwest Territories', 'Nunavut', 'Yukon'
                    ]} />
                  </Box>
                </Paper>
              </Grid>

            </Grid>
          </Grid>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
